<template>
  <div style="height: 100%">
    <div class="entity-header d-md-flex flex-row justify-space-between mb-8">
      <div class="d-flex flex-row align-center mr-0 mr-md-5 mb-5 mb-md-0">
        <icon-button class="mr-6" :to="backRoute">
          <v-icon>mdi-arrow-left</v-icon>
        </icon-button>

        <div v-if="!isLoading" class="d-flex flex-column flex-md-row">
          <h1 class="entity-header__title text-h5 text-md-h4 font-weight-bold flex-grow-0 flex-shrink-1">
            {{ agreemntName }}
          </h1>
        </div>
      </div>
      <div v-if="!isLoading && canDownload">
        <v-btn large color="primary" block class="d-md-none" @click="downloadAgreement">
          {{ $t('button.download') }}
          <tray-arrow-up-icon class="ms-2" />
        </v-btn>
        <v-btn large color="primary" block class="d-none d-md-flex" @click="downloadAgreement">
          {{ $t('button.download') }}
          <tray-arrow-up-icon class="ms-2" />
        </v-btn>
      </div>
    </div>

    <div v-if="isLoading" class="d-flex align-center justify-center" style="height: 80%">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div v-else-if="agreement" class="template d-md-flex">
      <div v-for="(submitter, index) in agreement.docusealData.submitters" :key="submitter.uuid">
        <div class="px-2 py-2">
          <div class="px-5 py-2" style="background:#FAFAFA;height:100%">
            <div class="d-flex flex-wrap">
              <div>
                <v-icon x-small class="me-2" :color="colors[index]">mdi-circle</v-icon>
                <span>{{ $t(`contracts.${submitter.role}`) }}</span>
              </div>
              <span class="ms-5">{{ submitter.role === 'contractor' ? submitter.email : submitter.name }}</span>
            </div>
            <div v-if="submitter.status === 'completed'" class="mt-4 d-flex">
              <v-chip color="green" text-color="white" style="width:100%;height:36px;" class="justify-center">{{
                $t('status.completed')
              }}</v-chip>
            </div>
            <div v-else class="mt-4 d-flex" @click="resendEmail(submitter)">
              <v-btn color="secondary" class="primary--text" block elevation="0">{{ $t('button.resend_email') }}</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Services
import contractService from '@/services/contract';
import notificationService from '@/services/notification';

// Constants
import { AGREEMENTS } from '@/constants/routes';

// Components
import TrayArrowUpIcon from '@/components/Icons/TrayArrowUpIcon.vue';
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'AgreementDetailed',

  components: {
    IconButton,
    TrayArrowUpIcon,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      colors: ['#FF5252', '#0000C4'],
      agreement: null,
    };
  },

  computed: {
    backRoute() {
      return { name: AGREEMENTS };
    },
    agreemntName() {
      return this.agreement?.document.name || '';
    },
    canDownload() {
      if (this.agreement?.docusealData.status === 'completed') return true;

      const submitters = [...this.agreement?.docusealData.submitters];

      for (let i = 0; i < submitters.length; i += 1) {
        const submitter = submitters[i];
        if (submitter.status === 'completed') return true;
      }

      return false;
    },
  },

  mounted() {
    this.getDocument();
  },

  methods: {
    getDocument() {
      contractService.getAgreement(this.id).then(res => {
        this.agreement = res;
        this.isLoading = false;
      });
    },

    resendEmail(submitter) {
      contractService
        .resendEmail(submitter.role, this.id)
        .then(() => {
          this.$options.notificationItem = notificationService.success(
            `${this.$t('agreement.email_has_been_sent')} ${
              submitter.role === 'contractor' ? submitter.email : submitter.name
            }`,
            2000
          );
        })
        .finally(() => {
          setTimeout(() => {
            notificationService.remove(this.$options.notificationItem);
            this.$options.notificationItem = null;
          }, 2000);
        });
    },

    downloadAgreement() {
      contractService.downloadAgreement(this.id, `${this.agreement.document.name.split('.')[0]}.pdf`);
    },
  },
  notificationItem: null,
};
</script>

<style lang="scss">
.entity-header {
  &__title {
    word-break: break-all;
  }
}
</style>
